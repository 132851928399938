<template>
  <div>
    <div class="content" @click='showtype=false'>
      <div class="all">
          <div class="tab">
              <el-tabs v-model="activeName" @tab-click="handleClick"  style="margin-left:25px">
                <el-tab-pane label="待上传方案" name="one"></el-tab-pane>
                <el-tab-pane label="带气方案记录" name="two"></el-tab-pane>
                </el-tabs>
            <div class="SearchBar">
              <!--搜索框-->
              <el-form ref="search" :inline="true" :model="search" size="small" >
                <el-row>
                  <el-form-item label="工程编号/名称" class="lab">
                    <el-input v-model="search.keyWord" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="现场管理员" class="lab">
                    <el-input v-model="search.siteManager" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="第三方施工队" class="lab">
                    <el-input v-model="search.constructionTeam" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="带气等级" class="lab">
                    <el-select v-model="search.jobLevel" clearable placeholder="请选择" class="SearchOptions">
                      <el-option
                          v-for="item in jobLevelList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="审核状态" class="lab" v-if="activeName=='two'">
                    <el-select v-model="search.checkType" clearable placeholder="请选择" class="SearchOptions">
                      <el-option
                          v-for="item in statusList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
                <el-form-item label="" class="lab" v-if="activeName=='two'">
                  <el-select v-model="search.time"  placeholder="请选择" class="SearchOptions2">
                    <el-option
                        v-for="item in dateTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="提交时间" class="lab" v-if="activeName=='one'">

                </el-form-item>
                <el-form-item label="">
                  <el-date-picker
                      class="SearchDate"
                      v-model="searchDatatime"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      clearable
                      value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-form-item>



                <span>
            <el-button type="primary" class="SearchBtn" size="small" @click="getList(1)">查询</el-button>
            <el-button type="primary" plain class="SearchBtn2" size="small" @click="reset">重置</el-button>
           </span>
              </el-form>
            </div>
        <div class="table">
          <!--数据表格-->
          <el-table height="calc(100vh - 328px)" :header-cell-style="{background:'#F9FAFE'}" ref="tableData" :data="tableData1" style="width: 100%;margin: 15px 0;" border   v-if="activeName=='one'"
             
            :stripe="true"
          >
           <el-table-column
                type="index"
                label="序号"
                align="center"
                >
                </el-table-column>
            <el-table-column
              prop="projectNumber"
              label="工程编号"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="constructionTeam"
              label="第三方施工队"
              align="center"
              show-overflow-tooltip
            >
           <template slot-scope="{ row }">
            <span @click="desc(row)">{{row.constructionTeam}}</span>
            </template>
            </el-table-column>
            <el-table-column
              prop="siteManager"
              label="现场管理员"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="jobLevelName"
              label="带气等级"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
           
            <el-table-column
              prop="acceptanceCheckWorkUrl"
              label="竣工签证单照片"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
            <el-button size="small" plain  @click="showImg(row)" >查看</el-button>
            </template>
            </el-table-column>
            <el-table-column
              prop="createBy"
              label="提交人"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="提交时间"
              align="center"
              show-overflow-tooltip
              sortable
            >
            
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left" width="200">
              <template slot-scope="{ row }">
                  
                     <el-button
                    type="primary"
                    size="small"
                    @click="addShow(row)" 
                    >提交方案</el-button>
                      
                    
              </template>
            </el-table-column>
          </el-table>
          <el-table height="calc(100vh - 445px)" :header-cell-style="{background:'#F9FAFE'}" ref="tableData" :data="tableData2" style="width: 100%;margin: 15px 0;" border   v-if="activeName=='two'"
             
            :stripe="true"
          >
            <el-table-column
                type="index"
                label="序号"
                align="center"
                >
                </el-table-column>
            <el-table-column
              prop="projectNumber"
              label="工程编号"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="constructionTeam"
              label="第三方施工队"
              align="center"
              show-overflow-tooltip
            >
           <template slot-scope="{ row }">
            <span @click="desc(row)">{{row.constructionTeam}}</span>
            </template>
            </el-table-column>
            <el-table-column
              prop="siteManager"
              label="现场管理员"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="jobLevel"
              label="带气等级"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
            <span >{{row.jobLevel==1?'一级':row.jobLevel==2?'二级':'三级'}}</span>
            </template>
            </el-table-column>
           <el-table-column
              prop="createBy"
              label="提交人"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="提交时间"
              align="center"
              show-overflow-tooltip
            >
            
            </el-table-column>
            
          <el-table-column
              prop="approvalStatus"
              label="审批状态"
              align="center"
              show-overflow-tooltip
              slot
            >
            <template slot-scope="{ row }">
              <span v-if="row.approvalStatus==1">待部长审批</span>
              <span v-if="row.approvalStatus==2">部长审批中</span>
              <span v-if="row.approvalStatus==3">上级领导审批中</span>
              <span v-if="row.approvalStatus==4">上上级领导审批中</span>
              <span v-if="row.approvalStatus==5">审批完成</span>
            </template>
            </el-table-column>
            <el-table-column
              prop="approvalResult"
              label="审批结果"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
              <el-tag v-if="row.approvalResult==1" type="primary" style="background-color:#67C23A ;color:#fff" size="small" plain>审批通过</el-tag>
              <el-tag v-if="row.approvalResult==2" type="info" style="background-color:#E94242;color:#fff" size="small" plain>审批驳回</el-tag>
              <el-tag v-if="row.approvalResult==3" type="info" style="background-color:#E6A23C;color:#fff" size="small" plain>未出结果</el-tag>
            </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left" width="200">
              <template slot-scope="{ row }">
                  
                     <el-button
                    type="primary"
                    size="small"
                    @click="sleveldesc(row)" 
                    >查看详情</el-button>
                      
                    
              </template>
            </el-table-column>
          
          </el-table>
          <div class="pageBox">
          <el-pagination
            :current-page="search.current"
            :background="true"
            :page-sizes="[30, 50, 100]"
            :page-size="search.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        </div>
         </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
    <el-form ref="addform" label-position="top" :model="form" label-width="120px"  size="mini" :rules="rule">
        <div >
         
          <el-form-item label="带气方案:" >
             <div>
                <el-upload
                ref="upload"
                action="#"
                :auto-upload="true"
                :http-request="repairBeforUploadFun"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :before-upload="beforUploadImg"
                :on-change="beforHFhandleChangeImg"
                :on-error = "errorFile"
                :on-progress = "errorFile"
                :file-list="repairBeforFilefileList"
                 accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                 :limit="20"
                >
                <div style="height:20px">
                <img src="../../assets/image/upload.png"/>
                </div>
                <div>
                <span>上传图片</span>
                </div>
            </el-upload>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="activeName=='one'">
        <el-button style="margin-right:10px" @click="dialogVisible = false">取消</el-button>
        <el-button type="success" @click="saveadd">保存</el-button>
     </div>
     <div slot="footer" class="dialog-footer" v-if="activeName=='two'">
        <el-button style="margin-right:10px" @click="dialogVisible = false">关闭</el-button>
     </div>
    </el-dialog>
    <el-dialog
      title="图片预览"
      :visible.sync="dialogVisibleImg"
      width="100%"
      :close-on-click-modal="false"
    >
    <div><img :src="dialogImageUrl" width="100%"/></div>
    </el-dialog>
    <show-img :imgList="imgList" ref="imgList"></show-img>
    <work-desc :objData="objData"  ref="workDesc"></work-desc>
    <level-desc :objData="objData"  ref="levelDesc"></level-desc>
  </div>
</template>
<script>
import {selectDictListByParentCode } from '@/apis/commonType'
import {gasHomework_planUploadedList,gasHomework_checkPendingType,gasHomework_planUploadedRecord} from '../../RequestPort/takeGas/takeGas'
import {uploadImg1} from "../../RequestPort/apply/apply.js"
import showImg from '../gongfu/showImg.vue'
import workDesc from './workDesc.vue'
import levelDesc from './levelDesc.vue'
import getNowFormatDate from "./../../common/js/nowDate.js";


export default {
  name: 'reportApply',
  components: {showImg,workDesc,levelDesc},
  mixins: [],
  data() {
    return {
      userList:[],
      search: { current: 1, size: 100,time: 1}, //搜索
      searchDatatime:[],
      total: 0,
      tableData1: [], //列表对象
      tableData2: [],
      id:'',
      activeName:'one',
      dialogVisible:false,
      imgList:[],
      rule:{
        
       },
       objData:{},
       form:{imageUrlList:[]},
      repairBeforFileFormData: new FormData(),
      repairBeforFilefileList:[],
      dialogVisibleImg:false,
      dialogImageUrl:'',
       dateTypeList:[{value:1,label:'审核时间'},{value:2,label:'提交时间'}],
       jobLevelList:[{value:1,label:'一级'},{value:2,label:'二级'},{value:3,label:'三级'}],
       statusList:[{value:1,label:'审批通过'},{value:2,label:'审批驳回'},{value:3,label:'未出结果'}],
       approvalStatusList:[{value:1,label:'待部长审批'},{value:2,label:'部长审批中 '},{value:3,label:'上级领导审批中'},{value:4,label:'上上级领导审批中'},{value:5,label:'审批完成'}],
       type:''
    }
  },
  mounted() {
      
      this.getList();
  },
  methods: {
      
      handleClick(val){
          this.getList(1);
      },
     showImg(row){
          if(row.acceptanceCheckWorkUrl){
             this.$refs.imgList.setdialogVisible(true);
             this.imgList = row.acceptanceCheckWorkUrl.split(',');
          }else{
              this.$message.error('暂无竣工签证单照片')
          }
          
      },
      desc(row){
      this.id = row.id+""
      this.$refs.workDesc.setdialogVisible(true)
    },
    sleveldesc(row){
        this.objData = row;
        this.$refs.levelDesc.setdialogVisible(true)
    },
      addShow(row,type){
          if(type==1){
              this.form.proposal = row.checkProposal
          }
          this.form.projectName = row.projectName;
          this.form.id = row.homeWorkId;
          this.dialogVisible = true;
      },
    async repairBeforUploadFun(e){
      this.repairBeforFileFormData = new FormData();
      this.repairBeforFileFormData.append('file',e.file)
       this.repairBeforFileFormData.append("text", '工程名称：'+this.form.projectName +'##当前时间：'+getNowFormatDate()+'##用户名：'+localStorage.getItem("userName"));
   
     await uploadImg1(this.repairBeforFileFormData).then((res)=> {
        this.form.imageUrlList.push(res.data.url)
        this.repairBeforFileFormData.delete('file')
      })
      },
      errorFile(err, file, fileList){
        console.log(err)
        this.$message.error('上传失败！')
      },
      beforUploadImg(file){
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ['jpg','jpeg','png','gif','bmp','JPG','JPEG','PBG','GIF','BMP'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是 jpg、jpeg、png、gif、bmp");
        return false;
      }
      const size = file.size / 1024 / 1024
      if (size > 100) {
        this.$message.error("大小必须小于100M");
        return false;
      }
    },
    beforHFhandleChangeImg(file, fileList) {
       this.repairBeforFilefileList = fileList
    },
    handleRemove(file, fileList) {
    let index = this.getArrayIndex(this.repairBeforFilefileList,file);
    this.form.imageUrlList.splice(index,1)
    },
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
      //重置查询条件
    reset() {
      ;(this.search = {
        current: 1,
        size: 100,
        time:1
      }),
       this.searchDatatime=[];
        this.getList(1)
    },
    getList(current) {
      this.search.startTime = ''
        this.search.endTime = ''
      if(this.searchDatatime){
        this.search.startTime = this.searchDatatime[0]
        this.search.endTime = this.searchDatatime[1]
      }
      
      if (current) {
        this.search.current = 1
      }
      //查列表
      if(this.activeName=='one'){
          if (current) {
                this.search.current = 1
            }
          gasHomework_planUploadedList(this.search).then(res => {
                this.tableData1 = res.data.records
                this.total = res.data.total
            })
      }
      if(this.activeName=='two'){
        if (current) {
                    this.search.current = 1
            }
            gasHomework_planUploadedRecord(this.search).then(res => {
                this.tableData2 = res.data.records
                this.total = res.data.total
            })
      }
        
    },
   

    handleSizeChange(val) {
        if(this.activeName=='one'){
            this.search1.size = val
        }else{
            this.search.size = val
        }
      
      this.getList(1)
    },
    handleCurrentChange(val) {
        if(this.activeName=='one'){
            this.search1.current = val
        }else{
            this.search.current = val
        }
      this.getList()
    },
    checkStatus(row){
        var data = {checkType:1,homeWorkId:row.id,type:1}
        gasHomework_checkPendingType(data).then(res=>{
            if(res.code==200){
                this.$message.success('通过成功！')
                this.getList(1)
            }else{
                this.$message.error(res.msg)
            }
             
        })
    },
   
    
    saveadd(){
      this.$refs['addform'].validate(valid => {
            if (valid) {
                if(this.form.imageUrlList&&this.form.imageUrlList.length>0){
                    var data = {homeWorkId:this.form.id,type:2,moldyUrl:this.form.imageUrlList.toString()}
                    gasHomework_checkPendingType(data).then(res=>{
                        if(res.code==200){
                            this.$message.success('提交方案成功！')
                            this.dialogVisible = false;
                            this.getList();
                            this.clearnF();
                        }else{
                             this.$message.error(res.msg)
                        }
                        
                    })
                }else{
                    this.$message.error('请上传带气方案')
                }
            }
            })
    },
    clearnF(){
        this.form={
            proposal:'',
            id:'',
            imageUrlList:[]
        }
        this.repairBeforFilefileList=[]
    }
   

}}
</script>

<style  lang="less" scoped>

::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
.tab{background-color: #fff;border-radius: 6px;margin: 10px;}
.searchType{width:210px;box-shadow: 1px 1px 5px #888888;position:absolute;z-index:100;background-color:#fff}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin-right: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchOptions{
  width: 150px;
}
.SearchOptions2{
  width: 100px;
}
.SearchDate{
  width: 300px;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding-top:0;
  border-radius: 5px;
  margin-top:0;
   background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 1%;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList{
  display:block;margin:10px 0px
}
</style>